export { matchers } from "/.svelte-kit/generated/client/matchers.js";

export const nodes = [
	() => import("/.svelte-kit/generated/client/nodes/0.js?t=1740160343228"),
	() => import("/.svelte-kit/generated/client/nodes/1.js"),
	() => import("/.svelte-kit/generated/client/nodes/2.js"),
	() => import("/.svelte-kit/generated/client/nodes/3.js"),
	() => import("/.svelte-kit/generated/client/nodes/4.js"),
	() => import("/.svelte-kit/generated/client/nodes/5.js?t=1740160343228"),
	() => import("/.svelte-kit/generated/client/nodes/6.js"),
	() => import("/.svelte-kit/generated/client/nodes/7.js"),
	() => import("/.svelte-kit/generated/client/nodes/8.js"),
	() => import("/.svelte-kit/generated/client/nodes/9.js"),
	() => import("/.svelte-kit/generated/client/nodes/10.js"),
	() => import("/.svelte-kit/generated/client/nodes/11.js"),
	() => import("/.svelte-kit/generated/client/nodes/12.js"),
	() => import("/.svelte-kit/generated/client/nodes/13.js"),
	() => import("/.svelte-kit/generated/client/nodes/14.js"),
	() => import("/.svelte-kit/generated/client/nodes/15.js"),
	() => import("/.svelte-kit/generated/client/nodes/16.js"),
	() => import("/.svelte-kit/generated/client/nodes/17.js"),
	() => import("/.svelte-kit/generated/client/nodes/18.js"),
	() => import("/.svelte-kit/generated/client/nodes/19.js"),
	() => import("/.svelte-kit/generated/client/nodes/20.js"),
	() => import("/.svelte-kit/generated/client/nodes/21.js"),
	() => import("/.svelte-kit/generated/client/nodes/22.js")
];

export const server_loads = [0];

export const dictionary = {
		"/": [~2],
		"/6m-sponsoring": [20],
		"/account": [~22],
		"/beleid": [19],
		"/cart": [~3],
		"/checkout/cancel": [17],
		"/checkout/success": [18],
		"/login": [~13],
		"/mail": [~4],
		"/oauth-redirect": [~12],
		"/pannenkoeken": [11],
		"/purchases": [~5],
		"/scan": [~15],
		"/scan/result/[id]": [~16],
		"/shop": [6],
		"/shop/[id]": [~7],
		"/signup": [~14],
		"/tickets": [~8],
		"/tickets/cancel": [9],
		"/tickets/success": [10],
		"/verify/[token]": [~21]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),
	
	reroute: (() => {}),
	transport: {}
};

export const decoders = Object.fromEntries(Object.entries(hooks.transport).map(([k, v]) => [k, v.decode]));

export const hash = false;

export const decode = (type, value) => decoders[type](value);

export { default as root } from "/.svelte-kit/generated/root.svelte";